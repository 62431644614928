import { i18n } from '@/plugins/i18n'

import { NoteOutput } from '@/api/types/note'
import { TaskOutput } from '@/api/types/task'

function getCorrespondingEntity<T = NoteOutput | TaskOutput>(property: 'name' | 'id', row: T | null): string | null {
  if (!row) return null

  let string = ''

  const EXCLUDE = ['assignedTo', 'solvedBy', 'parent']

  Object.keys(row).find((key) => {
    if (row[key]?.id && !EXCLUDE.includes(key)) {
      string = property === 'name' ? (i18n.t(`entities.${key}`) as string) : row[key].id
      return true
    }
    return false
  })

  return string
}

export { getCorrespondingEntity }
