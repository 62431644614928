
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiChevronDown, mdiChevronUp, mdiFilter, mdiPlus } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useFilter } from '@/composables/useFilter'

import { useGetTasks, useDeleteTask, useGetTasksBasic } from '@/api/task'
import { useGetUsersBasic } from '@/api/user'

import { getCorrespondingEntity } from '@/views/administration/utils'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { TaskOutput } from '@/api/types/task'
import { FILTER_FIELD_KEY } from '@/views/administration/views/tasks/types'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'Tasks',
  components: {
    CommonTableView,
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    AddEditTaskDialog: () => import('@/components/common/AddEditTaskDialog.vue'),
    TasksFilterBar: () => import('@/views/administration/views/tasks/components/TasksFilterBar.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
  },
  setup: (_, { root }) => {
    const TABLE_HEADERS = [
      {
        text: root.$t('administration.tasks.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('administration.tasks.table.col.title.entity'),
        value: 'entity',
        sortable: false,
      },
      {
        text: root.$t('administration.tasks.table.col.title.entityId'),
        value: 'entityId',
        sortable: false,
      },
      {
        text: root.$t('administration.tasks.table.col.title.title'),
        value: 'title',
        sortable: true,
      },
      {
        text: root.$t('administration.tasks.table.col.title.type'),
        value: 'type',
        sortable: true,
      },
      {
        text: root.$t('administration.tasks.table.col.title.content'),
        value: 'content',
        sortable: false,
      },
      {
        text: root.$t('administration.tasks.table.col.title.dueTo'),
        value: 'dueTo',
        sortable: true,
      },
      {
        text: root.$t('administration.tasks.table.col.title.assignedTo'),
        value: 'assignedTo.name',
        sortable: false,
      },
      {
        text: root.$t('administration.tasks.table.col.title.resubmission'),
        value: 'resubmission',
        sortable: false,
      },
      {
        text: root.$t('administration.tasks.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('administration.tasks.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof TaskOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'solvedBy',
      'solvedOn',
      'content',
      'parent',
    ]

    const activeTask = ref<TaskOutput | null>(null)

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(task: TaskOutput): void {
      activeTask.value = { ...task }

      isRowInfoDialogOpen.value = true
    }

    const isAddEditModalOpen = ref(false)

    const isEditMode = ref(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onClickEdit([_, { item: task }]: [void, { item: TaskOutput }]): void {
      if (hasSufficientRights(Rights.TASK_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        activeTask.value = { ...task }
      }
    }

    const isDeleteDialogOpen = ref(false)

    function onClickDelete(task: TaskOutput): void {
      activeTask.value = { ...task }

      isDeleteDialogOpen.value = true
    }

    const { deleteTask } = useDeleteTask()

    async function onDelete(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteTask(activeTask.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false

      activeTask.value = null

      debouncedCb.value()
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditModalOpen.value = false

      isDeleteDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeTask.value = null
    }

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()

    const { exec: getTasksBasic, data: tasksBasic, isLoading: isLoadingTasksBasic } = useGetTasksBasic()

    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!users.value?.length || !tasksBasic.value?.length) {
          getUsersBasic()
          getTasksBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.TaskIds,
        value: null,
        label: root.$t('administration.tasks.filters.taskIds'),
        items: computed(() => tasksBasic.value ?? []),
        isLoading: computed(() => isLoadingTasksBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Title,
        value: null,
        label: root.$t('administration.tasks.filters.title'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Type,
        value: null,
        label: root.$t('administration.tasks.filters.type'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.DueToFrom,
        value: null,
        label: root.$t('administration.tasks.filters.dueToSince'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.DueToTo,
        value: null,
        label: root.$t('administration.tasks.filters.dueToUntil'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.AssignedTo,
        value: null,
        label: root.$t('administration.tasks.filters.assignedTo'),
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ResubmissionFrom,
        value: null,
        label: root.$t('administration.tasks.filters.resubmissionSince'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ResubmissionTo,
        value: null,
        label: root.$t('administration.tasks.filters.resubmissionUntil'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.SolvedFrom,
        value: null,
        label: root.$t('administration.tasks.filters.solvedSince'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.SolvedTo,
        value: null,
        label: root.$t('administration.tasks.filters.solvedUntil'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.SolvedBy,
        value: null,
        label: root.$t('administration.tasks.filters.solvedBy'),
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    setSort(vuetifyTableOptions, { by: 'dueTo', desc: false })

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    const { exec: getTasks, data: tasks, isLoading: isLoadingTasks, paginationResponse } = useGetTasks()

    const totalTasks = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getTasks({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const {
      isOpen: isNotesDialogOpen,
      notes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<TaskOutput>(tasks, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiPlus,
        mdiFilter,
        mdiChevronDown,
        mdiChevronUp,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        tasks,
        isLoadingTasks,

        isAddEditModalOpen,
        isEditMode,

        activeTask,

        isRowInfoDialogOpen,

        isDeleteDialogOpen,

        isNotesDialogOpen,
        notes,
        notesEntityId,

        isFilterDropdownOpen,
        vuetifyTableOptions,
        totalTasks,
      },
      listeners: {
        onClickInfo,

        onClickEdit,

        onClickDelete,
        onDelete,

        onCloseAddEditDeleteInfoDialog,

        onOpenNotesDialog,
        onReloadNotes,

        onToggleFilterDropdown,
      },
      functions: {
        getCorrespondingEntity,

        debouncedCb,

        hasSufficientRights,
      },
    })
  },
})
