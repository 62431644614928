enum FILTER_FIELD_KEY {
  TaskIds = 'ids',
  Title = 'title',
  Type = 'type',
  DueToFrom = 'dueToFrom',
  DueToTo = 'dueToTo',
  AssignedTo = 'assignedToUserIds',
  ResubmissionFrom = 'resubmissionFrom',
  ResubmissionTo = 'resubmissionTo',
  SolvedFrom = 'solvedFrom',
  SolvedTo = 'solvedTo',
  SolvedBy = 'solvedByUserIds',
}

export { FILTER_FIELD_KEY }
